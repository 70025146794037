<script>
    export default {
        name: "ImageFile",
        created() {},
        data() {
            return {};
        },
        props: {
            img_url: [String, Object],
            file_name: String,
            file_id: Number,
            hideDeleteButton: Boolean,
        },
        methods: {
            deleteImg() {
                this.$emit('deleteImg', {"id" : this.file_id, "fileName": this.file_name });
            }
        },
    };
</script>

<template>
    <div class="img_box">
        <div class="img_box__head">
            <div class="img_box__fileName_container">
                <span>{{ file_name }}</span>
            </div>
            <button v-if="!hideDeleteButton" type="button" class="img_box__head--closebtn" @click="deleteImg"><i class="fa fa-times-circle" style="color:red;"></i></button>
        </div>
        <div class="img_box__img">
            <div class="img_box__img_container">
                <a :href="img_url" target="_blank">
                    <img :src="img_url" alt="img" @click="$emit('showImage', img_url)">
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.img_box {
    // max-height: 300px;
    width: 100%;
    height:  250px;
    background: #F0F4F9;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    
    &__fileName_container {
        max-width: calc(100% - 10%);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        
        span {
            // white-space: nowrap;
        }
    }

    &__head {
        width: 100%;
        height: 20%;
        position: relative;
        top: 0;
        left: 0;

        

        &--closebtn{
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            outline: none;
        }
    }

    &__img {
        width: 100%;
        height: 80%;
        
        &_container {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            
            object-fit: cover;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}
</style>